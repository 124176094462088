@charset "utf-8";

/*
------------------------------------------------------------
Project Name : ARZ Food Service;
------------------------------------------------------------
*/

/* Reset 
--------------------------------------------------------------------------------------------------------*/

/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
body { line-height: 1; }
ol, ul { list-style: none; }
blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
table { border-collapse: collapse; border-spacing: 0; }

/* General 
--------------------------------------------------------------------------------------------------------*/
* { outline: none !important; }
a { transition: all 0.3s; }
html, body { min-width: 320px; min-height: 100vh; }
body { font-size: 16px; line-height: 22px; color: #333333; font-family: 'Montserrat', sans-serif; -ms-overflow-style: scrollbar; -webkit-font-smoothing: subpixel-antialiased; }
html.page-loaded{ scroll-behavior: smooth; }
.wrapper { min-height: 100vh; }
a { color: #000; text-decoration: none; }
a:hover { color: #999; text-decoration: none; }
h1, h2, h3, h4, h5, h6 { line-height: 1.2em; padding: 0 0 20px; }
h1 span, h2 span, h3 span, h4 span, h5 span, h6 span { display: block; }
h1 { font-size: 60px; line-height: 70px; }
h2 { font-size: 38px; line-height: 42px;  }
h3 { font-size: 30px; line-height: 38px; }
h4 { font-size: 26px; line-height: 30px; }
p { padding: 0 0 20px; }
input[type=text], input[type=email], input[type=password], textarea { -webkit-appearance: none; appearance: none; }
::-webkit-input-placeholder { color: #000; }
::-moz-placeholder { color: #000; }
:-ms-input-placeholder { color: #000; }
:-moz-placeholder { color: #000; }
::-moz-focus-inner { border: 0; }
select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }
select::-ms-expand {display: none;}
option:not(:checked) { color: black; }

.bg-primary{ background: #0a2d71!important;}

.btn{padding: 14px 26px; border-radius: 0; font-weight: 600; box-shadow: none!important; text-transform: uppercase; line-height: 20px; min-height: 50px; }
.btn-primary{background-color: #0a2d71!important;border-color: #0a2d71!important; color:#fff!important}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus{background-color: #194292!important;border-color: #194292!important;}

.btn-white{background-color: #FFF!important;border-color: transparent!important; border: 0; color:#0a2d71!important;}
.btn-white:hover, .btn-white:active, .btn-white:focus{background-color: #0a2d71!important;color: #FFF!important; }

.btn-accent{ background-color: #ffe226!important;border-color: transparent!important; border: 0; color:#0a2d71!important }
.btn-accent:hover, .btn-accent:active, .btn-accent:focus{background-color: #ffe850!important;}


/* Banner
--------------------------------------------------------------------------------------------------------*/
.banner { display: flex; min-height: 600px; height: 100vh; background: url(../images/banner-bg.jpg) no-repeat center center; background-size: cover; align-items: center; text-align: center; position: relative; }
.banner .banner-content { flex-grow: 1; padding: 40px 40px 130px; }
.banner .banner-content .banner-logo { display: inline-block; max-width: 100%; }
.banner .banner-content .tag-line { color: #fff; margin-top: 40px; padding-bottom: 0; font-family: 'Alako'; }
.banner .scroll-down-btn { position: absolute; bottom: 50px; padding: 8px; border: solid 2px #fff; border-radius: 50px; left: 50%; transform: translate(-50%, 0); }
.banner .scroll-down-btn img { display: block; width: 32px; height: 32px; position: relative; }
.banner .scroll-down-btn:hover img { animation: downArrow 1.5s infinite linear; }
@keyframes downArrow {
    0% { top: 0; }
    25% { top: -3px; }
    75% { top: 3px; }
    100% { top: 0;}
}

/* NavBar
--------------------------------------------------------------------------------------------------------*/
.sticky-header{ position: sticky; top: 0; z-index: 1049; position: -webkit-sticky; }

.navbar{padding: 20px 15px;}
.navbar .navbar-brand{padding: 0; margin-right: 50px;}
.navbar .logo{display: block; width: 101px;}
.navbar .navbar-nav .nav-link{color: #fff; padding: 5px; position: relative;}
.navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link.active{color: #ffe226;}
.navbar .navbar-nav .nav-link:after{content: ""; position: absolute; left: 50%; right: 50%; width: 0; bottom: -39px; height: 4px; background-color: #ffe226; transition: all 0.3s;}
.navbar .navbar-nav .nav-link:hover:after, .navbar .navbar-nav .nav-link.active:after{right: 0; left: 0; width: 100%; }
.navbar .navbar-nav .nav-link + .nav-link{margin-left: 10px;}
.navbar .cart-btn{padding: 4px!important; width: 38px; height: 38px; position: relative; display: inline-block; vertical-align: top; min-height: auto; }
.navbar .navbar-collapse{transition: all 0.2s;}
.navbar .cart-btn > img{display: block; width: 28px; height: 28px; }
.navbar .cart-btn i{position: absolute; color: #0a2d71; font-size: 12px; line-height: 16px; font-weight: 500; background: #ffbe00; display: flex; justify-content: center; align-items: center; top: -2px; left: 50%; min-height: 20px; min-width: 20px; padding: 2px; border-radius: 18px;}
.navbar .navbar-nav .btn{ padding: 10px 26px; min-height: auto; }
.navbar .navbar-nav .btn + .btn{margin-left: 15px;}
.navbar .navbar-toggler{margin-left: 15px; border-color: #fff; padding: 4px 8px; }
.navbar .navbar-toggler-icon{ background-image: none;  }
.navbar .navbar-toggler{ border-radius: 0; border: 0; padding: 7px;display: inline-block; vertical-align: top; }
.navbar .navbar-toggler > span{ display: block; width: 24px;  height: 2px;background: #FFF;border-radius: 8px; }
.navbar .navbar-toggler > span + span{ margin-top: 7px; }

/* User DropDown
--------------------------------------------------------------------------------------------------------*/
.user-dropdown .dropdown-toggle{ background: none!important; border: 0; font-size: 16px; line-height: 20px; font-weight: 600; text-transform: none; }
.user-dropdown .dropdown-toggle::after{ border: 0; width: 12px; height: 12px;position: absolute;right: 4px;top: 15px; background: url('../images/chevron-down-white.svg') no-repeat center; background-size: contain; }
.user-dropdown .dropdown-menu{ box-shadow: 0 0 10px 0 rgba(30, 36, 35, 0.25); border-radius: 0!important; border: 0; padding: 0; position: relative; background-color: #ffffff;margin-right: -34px!important; margin-top: 18px!important;  }
.user-dropdown .dropdown-menu .dropdown-item{ position: relative; font-size: 16px; line-height: 18px; font-weight: 500; color: #1e2423; padding: 16px 31px 16px 55px; border-radius: 0; overflow: hidden; background: none!important; }
.user-dropdown .dropdown-menu .dropdown-item > i{ width: 28px;height: 28px; position: absolute; left: 16px; top: 10px; overflow: hidden; }
.user-dropdown .dropdown-menu .dropdown-item > i > img{ width: 64px;height: 28px; display: block; margin-left: -3px; transition: all 0.2s; }
.user-dropdown .dropdown-menu .dropdown-item:hover > i > img{  margin-left: -35px; }
.user-dropdown .dropdown-menu .dropdown-item:hover{ color: #df2b2b; }
.user-dropdown .dropdown-menu {position: relative;background: #ffffff;border-radius: .4em;}
.user-dropdown .dropdown-menu:after {content: '';position: absolute;top: 0;right: 34px;width: 0;height: 0;border: 9px solid transparent;border-bottom-color: #ffffff;border-top: 0;margin-left: -9px;margin-top: -9px;}

/* Main Content
--------------------------------------------------------------------------------------------------------*/
.content-block{padding: 80px 0; position: relative;}
.content-block .content-heading{font-family: 'Playfair Display', serif; color: #0a2d71;}

.about-block:before{ position: absolute; content: ""; width: 203px; height: 267px; background: url(../images/about-bg-left-top.png) no-repeat left top; background-size: contain; left: 0; top: 0; z-index: 1030; }
.about-block:after{position: absolute; content: ""; width: 285px; height: 215px; background: url(../images/about-bg-right-bottom.png) no-repeat left top; background-size: contain; right: 0; bottom: 10px;}

/* Conatct Block
--------------------------------------------------------------------------------------------------------*/
.contact-details-block{ padding: 50px; background: #0a2d71; color: #FFF; }
.contact-details-block .content-heading{ color: #FFF; font-weight: 700; padding-bottom: 35px; }
.contact-listing{ margin-bottom: -16px; }
.contact-listing li{ display: block; position: relative; max-width: 280px; padding-left: 38px; margin-bottom: 16px;font-size: 18px;font-weight: 300;line-height: 28px; }
.contact-listing li a{ color: #FFF; }
.contact-listing li a:hover, .contact-listing li a:hover, .contact-listing li a:focus{ color: #ffe226; }
.contact-listing li img{ display: block; position: absolute; top: 0; left: 0; width: 24px; height: 24px; }
.location-map{ min-height: 100%; width: 100%; }

/* Secondary Banner Block
--------------------------------------------------------------------------------------------------------*/
.secondary-banner-block.have-pannel{ height: 650px; padding: 200px 30px 235px; }
.secondary-banner-block{ height: 550px; padding: 172px 30px; text-align: center; position: relative; background: #000; background: url('../images/product-pricing-banner.jpg') no-repeat center; background-size: cover; }
.secondary-banner-block .description{ font-size: 38px; line-height: 44px; text-align: center; font-family: 'Playfair Display', serif; color: #FFF; max-width: 1030px; margin: 0 auto; }
.secondary-banner-block .accent-link{ display: inline-block; vertical-align: top; margin: 0 6px; color: #ffe226; position: relative; transition: all 0.2s; }
.secondary-banner-block .accent-link::after{ position: absolute; content: ""; bottom: 0; left: 0; right: 0; height: 1px; background: #ffe226; transition: all 0.2s; }
.secondary-banner-block .accent-link:hover::after, .secondary-banner-block .accent-link:active:after, .secondary-banner-block .accent-link:focus::after{ background: #FFF; left: 50%; right: 50%; }
.secondary-banner-block .btn{ padding: 14px 21px; }

.pricing-pannel{ background: #ffe226; text-align: left; padding: 44px 35px; margin: -90px auto 0;z-index: 1; position: relative; }
.pricing-pannel h2{color: #333!important; padding-bottom: 10px; }
.pricing-pannel span{ font-size: 16px; line-height: 24px; color: #333; display: block;  }

/* Supplier Block
--------------------------------------------------------------------------------------------------------*/
.supplier-block{ position: relative; }
.supplier-block::after{ position: absolute; content: ""; width: 233px; height: 281px; background: url(../images/supplier-right-bottom.png) no-repeat left top; background-size: contain; right: 0; bottom: -50px; z-index: 1; }
.supplier-list{ display: block; margin-left: -100px; margin-bottom: -18px; }
.supplier-list li{ text-align: right; display: flex; justify-content: flex-end; }
.supplier-list li a{ border: 1px solid #eeeeee; display: flex; justify-content: center; margin: 0 0 18px 18px; padding: 14px; width: 154px; height: 84px; overflow: hidden; transition: all 0.2s; }
.supplier-list li a > img{ display: inline-block; vertical-align: middle; width: 124px; height: auto; transition: all 0.2s;}
.supplier-list li a:hover > img, .supplier-list li a:active > img, .supplier-list li a:focus > img{ transform: scale(1.1); }
.supplier-list li a:hover, .supplier-list li a:active, .supplier-list li a:focus{ border-color: #0a2d71; box-shadow: 0 0 8px rgba(0,0,0,0.1); }

/* Footer
--------------------------------------------------------------------------------------------------------*/
.footer-block{ position: relative; background: #222222;padding: 44px 0; }
.footer-block::before{ content: ""; position: absolute; top: -90%; left: 0; width: 247px; height: 200px; background: url(../images/footer-bg-left.png) no-repeat left top; background-size: contain; }
.footer-block::after{ content: ""; position: absolute; top: -150%; right: 0; width: 164px; height: 280px; background: url(../images/footer-bg-right.png) no-repeat right top; background-size: contain; }
.footer-block .social-media-block{ min-height: 68px; padding: 11px 0; }
.footer-block .social-media-block > a{ position: relative; display: inline-block; vertical-align: top; transition: all 0.2s; padding: 8px; }
.footer-block .social-media-block > a::after{ content: ""; position: absolute; top: 50%; bottom: 50%; left: 50%; right: 50%; border-radius: 50%; background: rgba(255,255,255,0.1); transition: all 0.2s; }
.footer-block .social-media-block > a:hover::after, .footer-block .social-media-block > a:active::after, .footer-block .social-media-block > a:focus::after{ top: 0; bottom: 0; left: 0;right: 0; }
.footer-block .social-media-block > a > img{ width: 30px; height: 30px; display: inline-block; vertical-align: top; }

.footer-block .linking-block .secondary-nav{ display: block; margin-bottom: 10px; }
.footer-block .linking-block .secondary-nav li{ display: inline-block; vertical-align: top;  }
.footer-block .linking-block .secondary-nav li a{ display: inline-block; vertical-align: top; font-size: 16px; line-height: 20px; color: #FFF; font-weight: 300; padding: 8px 0; }
.footer-block .linking-block .secondary-nav li + li a{ margin-left: 20px; }
.footer-block .linking-block .secondary-nav li a:hover, .footer-block .linking-block .secondary-nav li a:active, .footer-block .linking-block .secondary-nav li a:focus{ color: #ffe226; }

.footer-block .linking-block .copyright-block p{  display: inline-block; vertical-align: middle; padding: 0; font-size: 14px; line-height: 18px; color: #a2a2a2; font-weight: 300; margin-right: 20px; }
.footer-block .linking-block .copyright-block span{ display: inline-block; vertical-align: middle;}
.footer-block .linking-block .copyright-block span > a{ display: inline-block; vertical-align: middle; font-size: 14px; line-height: 18px; color: #a2a2a2; font-weight: 300; margin-right: 20px; }
.footer-block .linking-block .copyright-block span > a:last-child{ margin-right: 0; }
.footer-block .linking-block .copyright-block span > a:hover, .footer-block .linking-block .copyright-block span > a:focus, .footer-block .linking-block .copyright-block span > a:active{ color: #ffe226;  }

/* Login Page
--------------------------------------------------------------------------------------------------------*/
.form-wrapper-outer{height: 100vh; max-height: 100vh; background-attachment: fixed; background: url('../images/login-bg.jpg') no-repeat center; background-size: cover; overflow: auto;}
.form-wrapper {  display: table; width: 100%; min-height: 100%; height: 100vh;}
.form-outer{ display: table-cell; vertical-align: middle; padding: 0 40px; }
.form-outer .form-block{ max-width: 600px; min-height: 460px; margin: 0 auto; }
.form-outer .form-block .form-header{ text-align: center; margin: 30px 0; }
.form-outer .form-block .form-header .logo{ display: inline-block; vertical-align: top; }
.form-outer .form-block .form-header .logo > img{ display: inline-block; vertical-align: top; width: 218px; height: 159px; }
.form-outer .form-block .form-body{ background: #FFF; padding: 48px 50px; box-shadow: 0.1px 2px 10px 0 rgba(0, 0, 0, 0.15); }
.form-outer .form-block .form-body h2{ text-align: center; color: #0a2d71; font-family: 'Playfair Display', serif; padding-bottom: 34px; }
.form-outer .form-block .form-body .btn-primary{ display: block;width: 100%; margin-bottom: 30px; }
.form-outer .form-block .form-body .register{ font-size: 16px; line-height: 24px; color: #666666; padding-bottom: 0; text-align: center; }
.form-outer .form-block .form-body .register a{ white-space: nowrap; line-height: 24px; }
.form-outer .form-block .form-footer{ padding: 40px 0; text-align: center; }


/* Product Navbar 
--------------------------------------------------------------------------------------------------------*/
.product-navbar{ display: block; border-bottom: 1px solid #dfdfdf; }
.product-navbar > ul{ display: flex; flex-wrap: nowrap; justify-content: space-around; padding: 0; }
.product-navbar > ul > li{ flex-grow: 1; }
.product-navbar li > .product-nav-item{ position: relative; display: block; padding: 30px 20px; white-space: nowrap; color: #0a2d71; font-weight: 500; font-size: 18px; line-height: 22px; text-transform: uppercase; text-align: center; }
.product-navbar li > .product-nav-item:after{ position: absolute; content: ""; left: 50%; right: 50%; bottom: 0; background: #0a2d71; height: 4px; transition: all 0.3s; }
.product-navbar li > .product-nav-item.active:after, .product-navbar li > .product-nav-item:hover:after, .product-navbar li > .product-nav-item:focus:after, .product-navbar li > .product-nav-item:active:after{ left: 0; right: 0; }

/* Categories Grid And Category Item
--------------------------------------------------------------------------------------------------------*/
.product-wrapper{ margin: 50px auto 150px;  }
.category-item{ overflow: hidden; display: block; min-height: calc(100% - 30px); margin: 0 0 30px; transition: all 0.2s; }
.category-item > img{ max-width: 100%; height: 165px; width: 100%; }
.category-item .category-item-footer{ background: #FFF; text-transform: uppercase; font-weight: 500; text-align: center; padding: 20px; margin-top: -6px;font-size: 16px; line-height: 24px; color: #333!important; }
.category-item:hover{ box-shadow: 0.1px 2px 10px 0 rgba(0, 0, 0, 0.15) }

/* Primary Breadcrumb
--------------------------------------------------------------------------------------------------------*/
.primary-breadcrumb{ margin-bottom: 30px; }
.primary-breadcrumb .breadcrumb{ background: none; padding: 0; }
.primary-breadcrumb .breadcrumb-item a, .primary-breadcrumb .breadcrumb-item{ color: #868686; font-size: 18px; line-height: 22px; text-transform: uppercase;; }
.primary-breadcrumb .breadcrumb-item+.breadcrumb-item::before{ content: ""; margin: 2px 6px 2px 0; width: 8px; height: 15px; background: url('../images/chevron-right.svg') no-repeat center; background-size: 14px 14px; }
.primary-breadcrumb .breadcrumb-item:last-child{ color: #0a2d71; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 200px}


/* Product Details 
--------------------------------------------------------------------------------------------------------*/
.product-details .product-img > i{ width: 450px; height: 450px; overflow: hidden; background: #f8f8f8; }
.product-details .product-img > i > img { display: block; width: 450px; height: 450px; max-width: 100%; }
.product-micro-detail{ padding: 40px 10px 20px; }
.product-micro-detail .product-name{ font-weight: 500; text-transform: uppercase; padding-bottom: 15px; color: #333333; text-overflow: ellipsis; max-width: 100%; overflow: hidden; white-space: pre-wrap; }
.product-micro-detail .price{ color: #0a2d71; font-size: 24px; line-height: 28px; font-weight: 600; padding-bottom: 26px; display: block; }
.product-micro-detail .out-of-stock{ color: #df2b2b; font-size: 20px; line-height: 28px; font-weight: 600; padding-bottom: 26px; display: block; }
.product-micro-detail .out-of-stock span{ color: #3166c7; font-size: 18px; line-height: 28px; font-weight: 600; display: inline; padding-left: 15px; text-decoration: underline; text-transform: capitalize;cursor: pointer;}


.product-micro-detail .action-bar{ display: flex; justify-content: flex-start; position: relative; flex-wrap: wrap; }
.number-input{ display: flex; align-items: center; position: relative; }
.number-input .action { position: absolute; top: 0; right: 0; padding: 5px 4px; }
.number-input .btn-icon{ display: block; width: 20px!important;height: 20px!important; min-width: 20px!important; min-height: 20px!important; padding: 0!important;margin: 0!important; }
.number-input .btn-icon> img { width: 12px; height: 12px; margin: 0 auto; }
.number-input .form-group .form-control{ padding-right: 30px; }
.product-micro-detail .action-bar .number-input{ margin: 0 15px 0 0; }
.product-micro-detail .action-bar .form-group{  width: 100px; position: relative; }
.product-micro-detail .action-bar .btn{  margin-bottom: 10px; margin-right: 15px; }
.product-micro-detail .action-bar .btn:last-child{ margin-right: 0; }

.product-micro-detail .que-modal-link{ display: inline-block; vertical-align: top; font-size: 18px; line-height: 22px; color: #3166c7; font-weight: 600; margin-bottom: 34px; text-transform: uppercase; }
.product-micro-detail .que-modal-link:hover, .product-micro-detail .que-modal-link:active, .product-micro-detail .que-modal-link:focus{ color: #ffe226; }

.product-micro-detail .product-description h6{ font-weight: 500; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #333; padding-bottom: 12px; margin-bottom: 16px; border-bottom: 1px solid #dfdfdf; }
.product-micro-detail .product-description p { font-size: 16px; line-height: 26px; color: #333; } 

/* product-lististing 
--------------------------------------------------------------------------------------------------------*/
.product-list-item-wrapper{ margin-bottom: -30px; }
.product-list-item{ display: block; cursor: pointer; } 
.product-list-item:hover .product-list-item-detail div.label-action > span{ color: #3166c7;}
.product-list-item:hover .product-list-item-detail::before{ right: 20px; }
.product-list-item:hover .product-list-item-img { transform: scale(1.05); }
.product-list-item:last-child .product-list-item-detail::after { display: none; }
.product-list-item .product-list-item-img{ width: 130px; height: 130px; overflow: hidden; display: inline-block; vertical-align: middle; transition: all 0.2s; }
.product-list-item .product-list-item-img > img { width: 130px; height: 130px;display: inline-block; vertical-align: middle; }
.product-list-item .product-list-item-detail {display: inline-block; position: relative; vertical-align: middle; margin: 30px 0; min-height: 130px; padding: 30px 65px 30px 40px; width: calc(100% - 130px); } 
.product-list-item .product-list-item-detail > div{ width: 100%; display: block; }
.product-list-item .product-list-item-detail::before{ content: ""; position: absolute; right: 30px; top: 50%; width: 12px; height: 22px; background: url('../images/product-list-chevron-right.svg') no-repeat center; background-size: 22px 22px; transition: all 0.4s; transform: translate(0 ,-50%);}
.product-list-item .product-list-item-detail::after{ content: ""; position: absolute; right: 0; bottom: -30px; left: 0; width: 100%; height: 1px; background: #dfdfdf;  }
.product-list-item .product-list-item-detail div.label-action > span, .product-list-item .product-list-item-detail span{ display: block; padding-bottom: 14px; width: 100%; font-size: 21px; min-height: auto; line-height: 25px; color: #333; text-transform: uppercase; font-weight: 500; }
.product-list-item .product-list-item-detail span{ color: #0a2d71; font-weight: 600; padding-bottom: 0; }
.product-list-item .product-list-item-detail p{ padding: 0; }
.product-list-item .product-list-item-detail div.label-action > span{position: relative; padding-right: 30px; }
.product-list-item .product-list-item-detail div.label-action > span .dropdown { display: inline-block; vertical-align: middle; position: absolute; margin-top: -3px; margin-left: 8px; }

.product-list-item{ display: flex;align-items: center; justify-content: space-between; }
.product-list-item.simple-product{ display: block; width: auto; }
.product-list-item.simple-product .product-list-item-detail{ display: inline-block; }
.product-list-item .product-list-item-detail{ display: flex; justify-content: space-between; align-items: center; }
.product-list-item .product-list-item-detail .form-group{ width: 100px; margin: 0; }
.product-list-item .product-list-item-detail .form-group .form-control{ border-color: #dedede; }
.product-list-item .default-menu .dropdown-menu{ padding: 10px 0; margin-top: 6px!important; }
.product-list-item .btn-more .dropdown-toggle{ min-width: 30px; min-height: 30px; padding: 0!important; border-radius: 50%; width: 30px; height: 30px; border: solid 1px #dedede!important; background: #fff url('../images/more.svg') no-repeat center!important; background-size: 14px 14px!important; }
.product-list-item .btn-more.show .dropdown-toggle, .product-list-item .btn-more .dropdown-toggle:hover{ background-color: #ececec!important; border-color: transparent!important; }
.product-list-item .btn-more .dropdown-toggle::after{ display: none; }
.product-list-item .product-list-item-detail .out-of-stock{color: #df2b2b;padding-top: 10px;}
.product-list-item .product-list-item-detail .out-of-stock span {display: inline; font-size: 16px; padding-left: 15px; text-decoration: underline; text-transform: capitalize;}
.product-list-item-detail .action-bar .listing-cart .btn{padding: 12px; font-size: 10px; min-width: 50px; margin-top: 10px;}
.product-list-item-detail .action-bar .listing-cart a.btn{padding: 12px; font-size: 10px; min-width: 100px; margin-top: 10px;}

    /* Modal Listing */
    .modal-body .product-list-item .product-list-item-detail .form-group .form-control{ padding: 8px 12px; height: 40px; }
    .modal-body .product-list-item .product-list-item-detail .form-group .number-input .action{ padding: 0 5px; height: 40px; }
    .modal-body .product-list-item .product-list-item-detail{ padding: 10px 15px 10px 15px; margin: 10px 0; min-height: auto; width: calc(100% - 80px); }
    .modal-body .product-list-item .product-list-item-detail::after{ bottom: -20px; }
    .modal-body .product-list-item .product-list-item-img, 
    .modal-body .product-list-item .product-list-item-img > img{ width: 50px; height: 50px; }
    .modal-body .product-list-item .product-list-item-detail::before{ background-size: 8px 8px; right: 4px; }
    .modal-body .product-list-item:hover .product-list-item-detail::before{ right: 8px; }
    .modal-body .product-list-item .product-list-item-detail div.label-action > span{ font-size: 14px; line-height: 16px; }
    .modal-body .product-list-item .product-list-item-detail div.label-action > span .dropdown{ margin-top: -2px; }
    .modal-body .product-list-item .product-list-item-detail span{ font-size: 14px; line-height: 16px; }
    .modal-body .product-list-item-detail .action-bar .listing-cart .btn{min-height: 40px; }

/* Sorting Block */
.sorting-block{ display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }
.sorting-block .form-group{ margin-bottom: 20px; margin-right: 30px; }
.sorting-block .form-group .form-control{ max-width: 100%; border: 1px solid #dedede; }
.sorting-block .sort-menu .btn-accent.dropdown-toggle{ line-height: 20px; min-height: 44px; margin-bottom: 20px;}
.sorting-block .sort-menu .btn-accent.dropdown-toggle::after{ border: 0; background: url('../images/sort.svg') no-repeat center; background-size: 18px 20px; margin-top: -4px; margin-left: 25px; width: 20px; height: 20px; display: inline-block; vertical-align: middle; }

.default-menu .dropdown-menu{ box-shadow: 0px 2px 8px 0 rgba(30, 36, 35, 0.15); border-radius: 0!important; border: 0; padding: 0; position: relative; background-color: #ffffff;margin-right: 0px!important; margin-top: 8px!important;  }
.default-menu .dropdown-menu .dropdown-item{ position: relative; font-size: 16px; line-height: 20px; font-weight: 500; color: #1e2423; padding: 10px 20px; text-align: left; border-radius: 0; overflow: hidden; background: none!important; text-transform: none; }
.default-menu .dropdown-menu .dropdown-item:hover{ color: #df2b2b;  }


/* Primary modal
--------------------------------------------------------------------------------------------------------*/
.primary-modal .modal-dialog.modal-sm{ max-width: 600px; margin: 0 auto; }
.primary-modal .modal-dialog.modal-lg{ max-width: 900px; margin: 0 auto; }
.primary-modal .modal-dialog.modal-xl{ max-width: 1100px; margin: 0 auto; }
.primary-modal .modal-content{ background: #FFF; border-radius: 0;box-shadow: -2px 0px 27px 0 rgba(0, 0, 0, 0.15);border: 0; margin: 15px; }
.primary-modal .modal-header{ display: block; text-align: center; border: 0; padding: 40px 55px 40px; position: relative; }
.primary-modal .modal-header .modal-title{ font-size: 24px; line-height: 28px; font-weight: 600; color: #0a2d71; padding-bottom: 16px; text-transform: uppercase; }
.primary-modal .modal-header .modal-title + .product-name{ font-size: 21px; line-height: 25px; font-weight: 500; color: #3166c7; padding: 0;text-transform: uppercase; }
.primary-modal .modal-header .btn-icon{ position: absolute; top: 9px; right: 14px; min-height: 40px; min-width: 40px; height: 40px; width: 40px; }
.primary-modal .modal-header .btn-icon > img{ width: 20px; height: 20px; }
.primary-modal .modal-body{ padding: 0 40px; }
.primary-modal .modal-footer{ display: block; border: 0; text-align: center; padding: 20px 40px; }
.primary-modal .modal-footer .btn{ display: block; width: 100%; margin: 0; }

/* Alert Modal 
--------------------------------------------------------------------------------------------------------*/
.alert-modal .modal-dialog.modal-sm{ max-width: 550px; margin: 0 auto; }
.alert-modal .modal-content{ background: #FFF; border-radius: 0;box-shadow: -2px 0px 27px 0 rgba(0, 0, 0, 0.15);border: 0; margin: 15px; }
.alert-modal .modal-body .btn-icon{ position: absolute; top: 9px; right: 14px; min-height: 40px; min-width: 40px; height: 40px; width: 40px; }
.alert-modal .modal-body .btn-icon > img{ width: 20px; height: 20px; }
.alert-modal .modal-body{ padding: 40px 70px; text-align: center; position: relative; }
.alert-modal .event-img{ width: 80px;height: 80px;display: block; margin: 0 auto 15px;  }
.alert-modal .event-title{ font-size: 24px; line-height: 34px; font-weight: 700;color: #333333; text-transform: uppercase; padding-bottom: 15px; }
.alert-modal .event-description{ font-size: 18px; line-height: 30px; font-weight: 400;color: #333333;padding-bottom: 0;  }


/* Ask Question 
--------------------------------------------------------------------------------------------------------*/
.question-wrapper .single-question{ display: block; margin-bottom: 20px; }
.question-wrapper .single-question:last-child{ margin-bottom: 0; }
.question-wrapper .single-question label{ position: relative; display: block; border: solid 1px #dedede; padding: 18px; font-weight: 500; text-transform: uppercase; font-size: 18px; line-height: 22px; cursor: pointer; transition: all 0.2s; text-align: center;}
.question-wrapper .single-question label:hover{ border-color: #0a2d71; box-shadow: 0 0 8px rgba(10, 45, 113, 0.15); color: #0a2d71; }
.question-wrapper .single-question input[type="radio"]{ width: 0; height: 0; opacity: 0; position: absolute;top: 0; bottom: 0; right: 0; left: 0; }
.question-wrapper .single-question input[type="radio"]:checked + label { border-color: #0a2d71; background: #0a2d71; box-shadow: 0 0 8px rgba(30, 36, 35, 0.25); color: #FFF; }
.btn-link-danger{ border: 0; color: #bd3636!important; border: 1px solid transparent; text-transform: capitalize; font-weight: 600; font-size: 18px; line-height: 22px; background: none!important; }
.btn-link-danger:hover{ border-color: #bd3636; }

/* Selected Question 
--------------------------------------------------------------------------------------------------------*/
.selected-question{ display: block; border: solid 1px #dedede; padding: 18px; font-weight: 500; text-transform: uppercase; font-size: 18px; line-height: 22px; margin-bottom: 20px; transition: all 0.2s; text-align: center; }


/* About Us Page 
--------------------------------------------------------------------------------------------------------*/
.about-block.no-bg::after, .about-block.no-bg::before{ display: none; }
.about-block .img-fluid{ min-height: 150px; }
.about-block.no-bg p{ font-weight: 300; font-size: 18px; line-height: 28px; color: #333; }

/* How it works */
.content-block.how-it-work{ padding: 60px 20px; background: #fbfbfb; }
.content-block.how-it-work .row{ margin-bottom: -30px; margin-top: 20px; }
.content-block.how-it-work .content-heading + p{ font-weight: 300; font-size: 18px; line-height: 22px; }
.info-card{ position: relative; background: #FFF; box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.1); height: calc(100% - 30px); margin-bottom: 30px; padding: 48px 38px 38px; cursor: pointer; }
.info-card::before{ content: ""; position: absolute; top: 0; height: 5px; left: 0; right: 0; background: #ffe226; transition: all 0.2s; transition-delay: 0.6s; } 
.info-card::after{ content: ""; position: absolute; top: 0; height: 5px; left: 50%; right: 50%; opacity: 0; visibility: hidden; background: #0a2d71; transition: all 0.3s; } 
.info-card:hover::after{ left: 0;right: 0; opacity: 1; visibility: visible; }
.info-card:hover{ box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.1);background: rgba(10, 45, 113, 0); }

.info-card > img{ width: 120px; height: 120px; display: block; margin: 0 auto 15px; }
.info-card .title{ font-size: 18px; line-height: 28px; color: #0a2d71; padding-bottom: 17px; text-align: center; font-weight: 600; text-transform: uppercase; }
.info-card p{ font-size: 16px; line-height: 26px; color: #333333; text-align: center; padding-bottom: 0; }

/* Explore Food Block */
.explore-food-block{ background: url('../images/explore-food.jpg') no-repeat center; background-size: cover; padding: 50px; color: #FFF; }
.explore-food-block .content-heading{ color: #FFF; padding-bottom: 6px; }
.explore-food-block p{ line-height: 26px; padding-bottom: 16px; font-weight: 300; font-weight: 300; }

/* service-block */
.service-block{ background: url('../images/ARZ-food-service.jpg') no-repeat center; background-size: cover; color: #FFF; padding: 80px 40px; }
.main-content{ background: #FFF; padding: 60px; box-shadow: 0px 2px 33.6px 1.4px rgba(30, 36, 35, 0.11); }
.service-block.content-block .content-heading{ padding-bottom: 30px; }
.service-block.content-block p{ color: #333; font-size: 18px; line-height: 28px; font-weight: 300; }


/* Empty Cart 
--------------------------------------------------------------------------------------------------------*/
.empty-cart-block{ display: table; table-layout: fixed; width: 100%; height: calc(100vh - 266px); }
.empty-cart-block .main-block{ display: table-cell; vertical-align: middle; text-align: center; padding: 30px 20px; }
.empty-cart-block .main-block > img{ display: block; width: 221px; height: 185px; margin: 0 auto 36px; }
.empty-cart-block .main-block h3{ font-weight: 600; color: #333333; padding-bottom: 12px; }
.empty-cart-block .main-block p{ font-size: 24px; line-height: 30px; padding-bottom: 24px; }

/* My Cart 
--------------------------------------------------------------------------------------------------------*/
.page-block{ margin: 50px 0; }
.page-block .page-title{ font-family: 'Playfair Display', serif; text-transform: uppercase; font-weight: 700; color: #0a2d71; font-size: 28px; line-height: 32px; padding-bottom: 26px;}
.page-block .page-back-link{ display: inline-block; vertical-align: top; font-size: 21px; line-height: 25px; color: #3166c7; text-transform: uppercase; font-weight: 700; margin-bottom: 26px; }
.page-block .page-back-link>img{ display: inline-block; vertical-align: middle; width: 18px; height: 18px; margin-right: 4px; margin-bottom: 2px; }
.page-block .page-back-link:hover, .page-block .page-back-link:active, .page-block .page-back-link:focus{ color: #0a2d71; }

/* Primary Grid */
.primary-grid.table{ width: 100%; margin-bottom: 0; }
.primary-grid.table thead{ background: #fbfbfb; border: solid 1px #dedede; }
.primary-grid.table thead th{ font-size: 21px; line-height: 25px; color: #0a2d71; vertical-align: middle; white-space: pre-line; font-weight: 500; padding: 20px; border: 0; }
.primary-grid.table tbody td{ font-size: 18px; line-height: 22px; color: #333; vertical-align: middle; white-space: pre-line; font-weight: 500; padding: 20px; border: 0; }
.primary-grid.table tbody td .form-group{ margin: 0; width: 70px; }

/* total block */
.total-block{ margin: 20px 0 30px; padding-top: 30px; padding-left: 0px; border-top: 1px solid #dfdfdf; }
.total-block .price-block{ display: flex; justify-content: space-between; align-items: center; }
.total-block .price-block + .price-block{ margin-top: 22px; }
.total-block .price-block p{ display: block; font-size: 21px; line-height: 25px; color: #333333; font-weight: 600; padding-bottom: 0; text-transform: uppercase; }
.total-block .price-block.total p:last-child{ font-size: 26px; line-height: 30px; color: #3166c7; }

/* checkout block */
.checkout-block .form-group{ margin-bottom: 20px; }
.checkout-block .form-group ::-webkit-input-placeholder { color: #c1c1c1; }
.checkout-block .form-group :-ms-input-placeholder { color: #c1c1c1;}
.checkout-block .form-group ::placeholder { color: #c1c1c1;}
.checkout-block .form-group .form-control{ border: 1px solid #dedede; }
.checkout-block .form-group textarea.form-control{ height: 128px; }
.checkout-block .btn-accent{ display: block; width: 100%; }
.checkout-block .btn-accent > span { margin-left: 6px; display: inline-block; vertical-align: top; }

/* Contact Page
--------------------------------------------------------------------------------------------------------*/
.contact-page-block iframe.bordered-map{ margin-top: 20px; margin-left: auto; margin-right: 20px; display: block; width: 545px; max-width: 545px; height: 430px; box-shadow: 20px -20px 0 0 #0a2d71; }
.contact-card-wrapper{ margin: 60px -15px 0; }
.contact-card{ display: block; position: relative; padding: 30px 110px 30px 30px; overflow: hidden; height: calc(100% - 30px); margin-bottom: 30px; cursor: pointer; transition: all 0.2s; background: #FFF; box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.1); -webkit-box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.1); }
.contact-card .card-heading{ position: relative; padding-bottom: 12px; color: #0a2d71;font-size: 18px; line-height: 22px; font-weight: 600; text-transform: uppercase; }
.contact-card .card-heading::before{ content: ""; position: absolute; left: -30px; top: -2px;  border-top: 12px solid transparent;border-bottom: 12px solid transparent;border-left: 14px solid #0a2d71; }
.contact-card p, .contact-card a, .contact-card address{ display: inline-block; vertical-align: top; font-size: 18px; line-height: 26px; color: #333333; padding-bottom: 0; }
.contact-card p{ display: block; }
.contact-card a:hover, .contact-card a:active, .contact-card a:focus{ color: #3166c7; }
.contact-card > img{ display: block; position: absolute; bottom: -10px;right: -10px; width: 150px; height: 125px; opacity: 0.07; transition: all 0.2s; }
.contact-card:hover > img{ opacity: 0.1; }
.contact-card:hover{  box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.05); }

/* My Order
--------------------------------------------------------------------------------------------------------*/
.my-order-wrapper{ margin-bottom: 30px; }
.my-order-card{ border: solid 1px #dedede; background-color: #ffffff; margin-bottom: 30px; padding: 25px; display: flex; justify-content: space-between; flex-wrap: wrap-reverse; }
.my-order-card > div{ padding-right: 30px; }
.my-order-card > div .labeled-data{ margin-bottom: 12px; }
.my-order-card > div .labeled-data:last-of-type{ margin-bottom: 20px; }
.my-order-card > div .labeled-data p, .my-order-card > div .labeled-data span{ width: 160px; padding-bottom: 0; font-size: 18px; line-height: 22px; color: #333333; display: inline-block; vertical-align: top; }
.my-order-card > div .labeled-data span{ font-weight: 700; width: auto; }
.my-order-card .orderID{ padding-bottom: 12px; font-size: 18px; line-height: 22px; font-weight: 500; color: #0a2d71; }
.my-order-card .btn{ display: inline-block;vertical-align: top; }
.my-order-card .btn + .btn{ margin-left: 20px; }

.my-order-wrapper .total-block { margin: 10px 0; padding: 30px 20px; }
.no-control.primary-grid.table tbody td, .no-control.primary-grid.table thead th{ padding: 17px 20px; }

/* Account Setting */
.account-setting{ margin-bottom: 90px; }
.information-block .section-heading{ padding-bottom: 18px; margin-bottom: 18px; color: #0a2d71; font-weight: 500;font-size: 21px; line-height: 25px; border-bottom: 1px solid #dfdfdf;  }
.editable-label{ display: block; margin: -16px 0 18px; }
.editable-label p{ font-size: 16px; line-height: 20px; color: #333333; padding-bottom: 0; display: flex;align-items: center; }
.editable-label p > span{ font-weight: 600;margin-right: 12px;  }
.editable-label p .btn{ min-height: 34px; text-transform: none; padding: 6px 22px; display: inline-block; vertical-align: middle; font-weight: 500; }


/* Loader 
--------------------------------------------------------------------------------------------------------*/ 
.loader-outer { position: absolute; top: 50%; left: 50%; margin-top: 0; transform: translate(-50%, -50%);display: inline-block; overflow: hidden; background: none; }
.loader-outer p{ font-size: 18px; line-height: 22px; color: #0a2d71; padding-bottom: 0; text-align: center; z-index: 2; }
@keyframes loader-1 {
	0% { top: 36px; height: 128px }
	50% { top: 60px; height: 80px }
	100% { top: 60px; height: 80px }
}
@keyframes loader-2 {
	0% { top: 41.99999999999999px; height: 116.00000000000001px }
	50% { top: 60px; height: 80px }
	100% { top: 60px; height: 80px }
}
@keyframes loader-3 {
	0% { top: 48px; height: 104px }
	50% { top: 60px; height: 80px }
	100% { top: 60px; height: 80px }
}
.loader { box-sizing: content-box;  box-sizing: content-box; width: 100%; height: 110px; position: relative; transform: translateZ(0) scale(0.6); backface-visibility: hidden; transform-origin: 0 0;    /* see note above */ }
.loader div { position: absolute; width: 20px; height: 30px; }
.loader div:nth-child(1) { left: 25px; background: #0a2d71; animation: loader-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite; animation-delay: -0.2s }
.loader div:nth-child(2) { left: 60px; background: #0a2d71; animation: loader-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite; animation-delay: -0.1s }
.loader div:nth-child(3) { left: 95px; background: #0a2d71; animation: loader-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite; animation-delay: 0s; }

/* Common Controls
--------------------------------------------------------------------------------------------------------*/
.form-group{ margin-bottom: 32px; position: relative; } 
.form-group label{ font-size: 14px; line-height: 18px; color: #666666; margin-bottom: 6px; display: block; }
.form-group .form-control{ border: 1px solid #c4c4c4; border-radius: 0; padding: 12px 18px; height: 50px; outline: none!important; font-size: 16px; line-height: 20px; color: #000; }
.form-group .form-control:focus, .form-group .form-control:active{ border-color: #0a2d71; box-shadow: 0 0 12px rgba(25, 66, 146, 0.1); }
.form-group .form-control.datepicker{ background: url('../images/calendar-light.svg') no-repeat left 18px center; background-size: 30px 30px; padding-left: 68px; }
.form-group textarea.form-control{ height: 90px; }
.form-group select.form-control{appearance: none; -webkit-appearance: none;-moz-appearance: none;text-indent: 1px;text-overflow: ''; cursor: pointer; background: url('../images/chevron-down.svg') no-repeat right 20px center; background-size: 18px 18px; padding-right: 58px; }
.invalid-control, .invalid-feedback{ color: #fd5555 !important; font-size: 12px !important; line-height: 16px !important; position: absolute;top: 100%; margin-top: 4px; left: 0; white-space: nowrap; text-transform: capitalize !important;}

.floatLabel { margin: 30px 0 40px; position: relative; }
.floatLabel label.control-label{ position: absolute; bottom: 20px; left: 0; font-size: 21px; line-height: 20px; color: #565656; margin: 0; transition: all 0.2s; }    
.floatLabel .form-control{ border: 0; border-bottom: 2px solid #dfdfdf; position: relative; font-weight: 600; padding: 18px 0; background: none; box-shadow: none!important; height: auto; font-size: 21px; line-height: 25px; color: #333333; }
.floatLabel:after{ content: ""; position: absolute; left: 50%; right: 50%; height: 2px; background: #0a2d71; bottom: 0; transition: all 0.2s; }
.floatLabel .form-control:focus, .floatLabel .form-control:active{ border-color: #dfdfdf; }
.floatLabel.has-focused label.control-label, .floatLabel.has-value label.control-label{ transform: translate(0, -30px); font-size: 14px; color: #0a2d71; }
.floatLabel.has-focused:after{ left: 0; right: 0; }

.primary-link{ position: relative; font-size: 16px; line-height: 20px; color: #0a2d71!important; font-weight: 500; }
.primary-link:after{ content: ""; position: absolute; bottom: -2px; right: 50%; left: 50%; height: 1px; background: #0a2d71; transition: all 0.2s; }
.primary-link:hover:after, .primary-link:active:after, .primary-link:focus:after{ left: 0; right: 0; }

.white-link{ font-size: 16px; line-height: 20px; color: #FFF!important; font-weight: 500; }
.white-link:hover, .white-link:active, .white-link:focus{ color: #ffe226!important; }

.btn-icon{ position: relative; background: none!important; border: 0; padding: 10px; border-radius: 50%; width: 50px; height: 50px;min-width: 50px; }
.btn-icon::after{ content: ""; position: absolute; top: 50%; bottom: 50%; left: 50%; right: 50%; transition: all 0.2s; background: rgba(10, 45, 113, 0.07); border-radius: 50%; }
.btn-icon.danger::after{ background: rgba(205, 21, 25, 0.08); }
.btn-icon:hover::after, .btn-icon:active::after, .btn-icon:focus::after{ top: 0; left: 0; bottom: 0;right: 0; }
.btn-icon:hover, .btn-icon:active, .btn-icon:focus{ background: none!important; }
.btn-icon > img{ width: 30px; height: 30px; display: block; }

.btn-iconic-text{ position: relative; overflow: hidden; border: 1px solid #3166c7!important; border-radius: 0; background: none!important; padding: 12px 20px; }
.btn-iconic-text::after, .btn-iconic-text::before{ width: 100%;height:100%;z-index: 3;content:'';position: absolute;top:0;left:0;box-sizing: border-box;-webkit-transform: scale(0);transform: scale(0);transition: 0.3s; }
.btn-iconic-text::before {border-bottom: 1px solid #0a2d71;border-left: 1px solid #0a2d71;-webkit-transform-origin: 0 100%;transform-origin: 0 100%;}
.btn-iconic-text::after {border-top: 1px solid #0a2d71;border-right: 1px solid #0a2d71;-webkit-transform-origin: 100% 0%;transform-origin: 100% 0%;}
.btn-iconic-text:hover::after, .btn-iconic-text:hover::before, .btn-iconic-text:focus::after, .btn-iconic-text:focus::before, .btn-iconic-text:active::after, .btn-iconic-text:active::before {-webkit-transform: scale(1);transform: scale(1);}
.btn-iconic-text > img{ display: inline-block; vertical-align: middle; width: 24px;height: 24px; margin-right: 6px;  }
.btn-iconic-text > span{ display: inline-block; vertical-align: middle; color: #3166c7!important; font-size: 18px; line-height: 24px; font-weight: 500;  }
.btn-iconic-text:hover, .btn-iconic-text:active, .btn-iconic-text:focus{ background: none!important; }

.btn-strock{position: relative; overflow: hidden; border: 1px solid #0a2d71!important; border-radius: 0; background: none!important; color: #0a2d71!important; padding: 12px 20px;  }
.btn-strock::after, .btn-strock::before{ width: 100%;height:100%;z-index: 3;content:'';position: absolute;top:0;left:0;box-sizing: border-box;-webkit-transform: scale(0);transform: scale(0);transition: 0.3s; }
.btn-strock::before {border-bottom: 1px solid #0a2d71;border-left: 1px solid #0a2d71;-webkit-transform-origin: 0 100%;transform-origin: 0 100%;}
.btn-strock::after {border-top: 1px solid #0a2d71;border-right: 1px solid #0a2d71;-webkit-transform-origin: 100% 0%;transform-origin: 100% 0%;}
.btn-strock:hover::after, .btn-strock:hover::before, .btn-strock:focus::after, .btn-strock:focus::before, .btn-strock:active::after, .btn-strock:active::before {-webkit-transform: scale(1);transform: scale(1);}
.btn-strock:hover, .btn-strock:active, .btn-strock:focus{ background: none!important; }


.text-priamry{ color: #0a2d71; }
.text-secondary{color: #666666; }
.bold{ font-weight: 700; }

/* .is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip{ position: absolute; max-width: 100%; width: auto; } */


/* Common Structure 
--------------------------------------------------------------------------------------------------------*/
.wrapper{ padding-bottom: 156px; }
.footer-block{ margin-top: -156px; min-height: 156px; max-height: 156px; }
.small_device a, .large_device a{color: #f8f9fa;}
.small_device a:hover, .large_device a:hover{color: #212529;}

/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
@media (max-width:1600px) {

    .footer-block::before{ top: -60%;width: 168px;height: 140px; }
    .footer-block::after{ top: -110%; width: 134px; height: 230px; }

    .supplier-block::after{ width: 193px;height: 231px; bottom: -60px; }
    .my-order-wrapper{ margin-bottom: 90px; }
}
@media (max-width:1400px) {
    .about-block:after{ bottom: 0; width: 185px; height: 145px; }
    .about-block:before{ width: 103px;height: 137px; }
    .supplier-block::after{ left: 0; right: auto; transform: rotate(180deg); bottom: -140px; }
    .footer-block{ margin: 100px 0 0; }
    .footer-block:before{ top: -75%; width: 168px; height: 140px; }
    .footer-block::after{ top: -100%; width: 114px; height: 190px;  }
    .secondary-banner-block.have-pannel{ height: 500px; padding: 135px 30px 185px; }
    .secondary-banner-block{ height: 450px; padding: 125px 30px; }
    .secondary-banner-block .description{ font-size: 32px; line-height: 42px; max-width: 100%; margin: 0 auto; width: 100%; }

    .product-wrapper{ margin: 50px auto; }
    .wrapper{ padding-bottom: 256px; }
    .footer-block{ margin-top: -156px; }
    .user-dropdown .dropdown-menu{ margin: 18px 0 0!important; }

    .empty-cart-block { height: calc(100vh - 366px); }

    .contact-page-block iframe.bordered-map{ max-width: 90%; }
    .my-order-wrapper{ margin-bottom: 70px; }

 }
@media (min-width:1200px) {
    .container-xl{max-width: 1170px;}
    .small_device{display: none;}
}

@media (max-width:1199px) { 
    .form-outer .form-block .form-body{ padding: 30px; }

    .supplier-block::after{ bottom: auto; top: 250px; }
    .pricing-pannel{ padding: 30px; margin: -80px 20px 0; }
    .pricing-pannel span{ margin-bottom: 20px; } 

    .supplier-list{ margin: 40px 0 0 0; }
    .supplier-block{ text-align: center; }
    .supplier-block p{ max-width: 70%; margin: 0 auto; }
    .supplier-list li{ justify-content: center; }

    .product-details .product-img > i{ width: 300px; height: 300px; }
    .product-details .product-img > i > img { width: 300px; height: 300px; max-width: 100%; }
    .product-micro-detail{ padding: 0 0 20px; }

    .product-list-item .product-list-item-detail{ padding: 30px 65px 30px 30px; }

    
    .primary-grid.table thead th{ font-size: 18px; line-height: 22px; padding: 15px 10px; }
    .primary-grid.table tbody td{ font-size: 16px; line-height: 20px;padding: 10px; }
    .primary-grid.table tbody td .form-group .form-control{ height: 40px; }
    .total-block{ padding: 20px 0px 20px; }
    .total-block .price-block p{ font-size: 18px; line-height: 22px; }
    .total-block .price-block + .price-block{ margin-top: 22px; }
    .total-block .price-block.total p:last-child{ font-size: 24px; line-height: 28px; }

    .account-setting{ margin-bottom: 50px; }
    .number-input .action { padding: 0px 4px; }
    .small_device{display: block;}
    .large_device{display: none;}
}
@media (max-width:991px) {
    .form-outer .form-block{ max-width: 500px; }

    h1 { font-size: 40px; line-height: 50px; }
    h2 { font-size: 34px; line-height: 38px; }
    h3 { font-size: 24px; line-height: 30px; }
    h4 { font-size: 22px; line-height: 28px; }
    .banner{ min-height: 480px; }
    .banner .banner-content .banner-logo { width: 300px; }

    .navbar .navbar-nav .nav-link{padding: 7px;}
    .navbar .navbar-nav .nav-link:after{display: none;}
    .navbar .navbar-nav .nav-link + .nav-link{margin-left: 0;}
    .navbar .navbar-nav .btn{padding: 10px 10px 8px;}
    .navbar .navbar-nav .btn + .btn{margin-left: 0; margin-top: 10px;}
    .navbar .navbar-collapse{padding-top: 10px;}

    .content-block{padding: 60px 0 30px;}
    .location-map{ min-height: 350px; margin-bottom: -5px; }
    .contact-details-block{ padding: 30px; }
    .contact-listing li{ max-width: 100%; }
    .contact-listing li img{ top: 1px; }

    .footer-block::after, .footer-block::before, .supplier-block::after, .about-block:before, .about-block::after{display: none;}
    .content-block.pricing-pannel{ padding: 20px; margin: -80px 0 0; }
    
    .secondary-banner-block.have-pannel{ height: auto; padding: 70px 30px 130px; }
    .secondary-banner-block{ height: auto; padding: 100px 30px; }
    .secondary-banner-block .description{ font-size: 26px; line-height: 34px; }

    .wrapper{ padding-bottom: 165px; }
    .footer-block{ margin: 0; }
    .footer-block{ display: table; width: 100%; table-layout: fixed; text-align: center; padding: 20px 15px; margin-top: -165px; min-height: 165px; max-height: 100%; }
    .footer-block .container-xl{ display: table-cell; vertical-align: middle; }
    .footer-block .social-media-block{ margin-bottom: 10px; padding: 0; min-height: auto; }


    .product-wrapper{ margin: 30px auto; }
    .product-navbar li > .product-nav-item{ padding: 20px; }

    .user-dropdown .dropdown-toggle::after{ position: static;display: inline-block;vertical-align: middle;margin-left: 18px; }
    .user-dropdown .dropdown-toggle{ padding: 7px!important; display: block;width: 100%; text-align: left; font-weight: 400; }
    .user-dropdown .dropdown-menu{ background: none; box-shadow: none; margin: 0!important; top: 0!important; }
    .user-dropdown .dropdown-menu .dropdown-item{ font-size: 14px; line-height: 18px; background: none; padding: 8px 18px; color: #FFF; font-weight: 400; }
    .user-dropdown .dropdown-menu .dropdown-item > i, .user-dropdown .dropdown-menu:after{ display: none; }
    .user-dropdown .dropdown-toggle:hover, .user-dropdown .dropdown-menu .dropdown-item:hover{ color: #ffe226!important; }

    .product-list-item-wrapper{ margin: 0; }
    .product-list-item .product-list-item-detail .form-group .form-control{ padding: 8px 12px; height: 50px; }
    .product-list-item .product-list-item-detail{ padding: 20px 40px 20px 30px; margin: 20px 0; min-height: auto; width: calc(100% - 80px); }
    .product-list-item .product-list-item-detail::after{ bottom: -20px; }
    .product-list-item .product-list-item-img, .product-list-item .product-list-item-img > img{ width: 80px; height: 80px; }
    .product-list-item .product-list-item-detail::before{ background-size: 16px 16px; right: 8px; }
    .product-list-item:hover .product-list-item-detail::before{ right: 15px; }
    .product-list-item .product-list-item-detail div.label-action > span{ font-size: 18px; line-height: 28px; }
    .product-list-item .product-list-item-detail div.label-action > span .dropdown{ margin-top: -2px; }
    .product-list-item .product-list-item-detail span{ font-size: 18px; line-height: 22px; }

    .content-block.how-it-work .row{ margin-top: 10px; }
    .info-card{ padding: 30px 20px; }

    .explore-food-block{ padding: 30px; }
    .service-block{ padding: 40px 20px; }
    .main-content{ padding: 30px 25px 15px; }
    .service-block.content-block p, .content-block p{ font-size: 16px; line-height: 24px; padding-bottom: 14px; }

    .empty-cart-block .main-block > img{ width: 190px; height: 150px; margin: 0 auto 25px; }
    .empty-cart-block .main-block h3{ padding-bottom: 8px; }
    .empty-cart-block .main-block p{ font-size: 20px; line-height: 26px; }

    .page-block{ margin: 40px 0; }
    .page-block .page-title { font-size: 24px; line-height: 30px; padding-bottom: 18px; }
    .page-block .page-back-link>img{ margin-bottom: 4px; width: 16px; height: 16px; } 
    .page-block .page-back-link{ margin-bottom: 18px; font-size: 18px; line-height: 22px; }

    .total-block{ margin: 20px 0 0; padding: 25px 10px 30px; }
    .checkout-block .btn-accent{ width: auto; margin: 0 auto; }

    .alert-modal .modal-body{ padding: 30px; }
    .alert-modal .event-img{ width: 70px; height: 70px; margin: 0 auto 18px; }
    .alert-modal .event-title{ font-size: 22px; line-height: 28px; }
    .alert-modal .event-description{font-size: 16px; line-height: 24px; }

    .contact-page-block iframe.bordered-map{ margin: 30px 0 0 0; width: 100%; max-width: 100%; box-shadow: none; height: 300px; }
    .contact-card-wrapper{ margin: 30px -15px -30px; }
    .contact-card{ padding: 20px 100px 20px 25px; }
    .contact-card p, .contact-card a{ font-size: 16px; line-height: 20px; }
    .contact-card .card-heading::before{ top: 0px; left: -25px;border-top: 9px solid transparent;border-bottom: 9px solid transparent;border-left: 10px solid #0a2d71; }
    .contact-card > img{ width: 110px; height: 95px; }

    .sorting-block .sort-menu .btn-accent.dropdown-toggle::after{ margin-left: 8px; }
    .sorting-block .sort-menu .btn-accent.dropdown-toggle{ padding: 15px 18px; }
    .form-group select.form-control{ background: url('../images/chevron-down.svg') no-repeat right 12px center; background-size: 12px 12px;padding-right: 34px; }

    .my-order-wrapper{ margin-bottom: 30px; }
    .editable-label{ margin: 0 0 20px; }
    .information-block .section-heading{ font-size: 18px; line-height: 22px; padding-bottom: 12px; }
    .small_device{display: none;}

}
@media (max-width:767px) {
    .form-outer .form-block .form-body{ padding: 25px 20px; }
    .form-outer .form-block .form-header .logo > img { width: 80%; height: auto; }

    h1 { font-size: 25px; line-height: 35px; }
    h2 { font-size: 30px; line-height: 34px; }
    h4 { font-size: 20px; line-height: 26px; }
    .btn{ padding: 11px 22px!important;font-size: 14px;line-height: 18px; min-height: 40px; max-height: 40px; }
    .btn-iconic-text{ padding: 7px 15px!important; }
    .btn-iconic-text > span{ font-size: 14px;line-height: 18px; min-height: auto; }
    .banner{ min-height: 320px; }
    .banner .banner-content { padding: 20px 20px 90px; }
    .banner .banner-content .banner-logo { width: 200px; }
    .banner .banner-content .tag-line { margin-top: 20px; }
    .banner .scroll-down-btn { bottom: 20px; }

    .content-block,.content-block.how-it-work{padding: 40px 0;}
    .supplier-block p{ max-width: 100%; margin: 0 auto; }
    .supplier-list{ margin-top: 30px; }
    .supplier-list li{ flex-wrap: wrap;}
    .supplier-list li a{ margin: 0 6px 12px; }

    .contact-details-block{ padding: 25px 20px; }
    .contact-listing li{ font-size: 16px; line-height: 24px; }
    .contact-listing li img{ top: -2px; }
    .location-map{ min-height: 300px; }

    .wrapper{ padding-bottom: 192px; }
    .footer-block{ margin-top: -192px; min-height: 192px; max-height: 100%; }
    .footer-block .linking-block .copyright-block{ display: flex; flex-wrap: wrap; }
    .footer-block .linking-block .copyright-block p{ display: block; order: 2; flex-basis: 100%; margin: 0; }
    .footer-block .linking-block .copyright-block span{ order: 1; display: block; text-align: center; flex-basis: 100%; margin-bottom: 10px; }

    .form-group{ margin-bottom: 28px; }
    .form-group .form-control{ padding: 9px; height: 42px; font-size: 14px; line-height: 18px; }
    .form-outer .form-block .form-body .register{ font-size: 14px; line-height: 18px; }
    .form-group .form-control.datepicker{ background-position: left 10px center; background-size: 24px 24px; padding-left: 44px; }
    .white-link, .primary-link{ font-size: 14px; line-height: 18px; }

    .floatLabel { margin: 30px 0 40px; }
    .floatLabel:last-child{ margin-bottom: 30px; }
    .floatLabel label.control-label{ bottom: 10px; font-size: 16px; line-height: 20px;  }    
    .floatLabel .form-control{ font-weight: 600; padding: 12px 0; font-size: 16px; line-height: 20px; }
    .floatLabel.has-focused label.control-label, .floatLabel.has-value label.control-label{ transform: translate(0, -24px); }

    .product-details .product-img{ text-align: center; margin-bottom: 20px; }
    .product-details .product-img > i{ width: 250px; height: 250px; }
    .product-details .product-img > i > img{ margin: 0 auto; width: 250px; height: 250px; }
    .product-micro-detail{ padding: 0; }
    .product-micro-detail .product-name{ padding-bottom: 8px; }
    .product-micro-detail .price{ padding-bottom: 20px; }
    .product-micro-detail .que-modal-link{ margin-bottom: 24px;font-size: 16px; line-height: 20px; }
    .product-micro-detail .product-description h6{ margin-bottom: 12px; font-size: 16px; line-height: 20px; }
    .product-micro-detail .product-description p{ font-size: 14px; line-height: 22px; padding-bottom: 10px; }

    .primary-breadcrumb .breadcrumb-item a, .primary-breadcrumb .breadcrumb-item{ font-size: 14px; line-height: 24px; }
    .primary-breadcrumb .breadcrumb-item+.breadcrumb-item::before{ margin: 3px 6px 3px 0; background-size: 12px 12px; }
    
    .product-navbar{ overflow-x: auto; }
    .product-navbar li > .product-nav-item{ font-size: 16px; line-height: 20px;}
    .product-navbar > ul{  justify-content: stretch;}

    .product-wrapper{ margin: 20px auto; }
    .category-item > img{ height: auto; }

    .primary-modal .modal-dialog.modal-sm{ max-width: 575px; }
    .primary-modal .modal-header .btn-icon{ top: 7px; right: 10px; }
    .primary-modal .modal-header{ padding: 50px 20px 20px; }
    .alert-modal .modal-body{padding: 50px 25px 25px; }
    .primary-modal .modal-footer{ padding: 20px; }
    .primary-modal .modal-header .modal-title{ font-size: 22px; line-height: 26px; }
    .primary-modal .modal-header .modal-title + .product-name{ font-size: 18px; line-height: 22px; }
    .primary-modal .modal-body{ padding: 0 20px; }
    .modal-dialog{ margin: 15px; }
    .selected-question{ padding: 12px; }
    .question-wrapper .single-question{ margin-bottom: 14px; }
    .question-wrapper .single-question label, .selected-question{ padding: 9px; font-size: 14px; line-height: 18px; }

    .page-block{ margin: 30px 0; }

    .total-block{ padding: 15px 0px 20px; }
    .total-block .price-block + .price-block{ margin-top: 16px; }
    .primary-grid.table{ margin-bottom: 10px; }
    .primary-grid.table thead th{ font-size: 16px; line-height: 20px; padding: 12px 10px; }
    .primary-grid.table tbody td{ font-size: 14px; line-height: 18px; padding: 6px 10px; }
    .primary-grid.table tbody td .form-group .form-control{ height: 34px; }
    .btn-icon{ width: 40px;height: 40px; min-width: 40px; padding: 9px!important;  }
    .btn-icon > img { width: 22px;height: 22px; }  
    .total-block .price-block p{ font-size: 16px; line-height: 20px; }
    .total-block .price-block.total p:last-child{ font-size: 20px; line-height: 24px; }

    .contact-card-wrapper{ margin: 30px -15px -20px; }
    .contact-card{ height: calc(100% - 20px); margin-bottom: 20px; }

    /* .product-list-item{ align-items: flex-start; }
    .product-list-item .product-list-item-img{ margin-top: 48px; } */
    .product-list-item .product-list-item-detail{ display: block; }
    .product-list-item .product-list-item-detail div.label-action > span{ padding-bottom: 8px; }
    .product-list-item .product-list-item-detail .form-group{ width: 80px; margin-top: 10px; }

    .my-order-card{ padding: 20px 20px 5px; margin-bottom: 20px; }
    .my-order-card .btn{ margin-right: 15px; margin-bottom: 15px; }
    .my-order-card .btn + .btn { margin-left: 0; } 
    .my-order-wrapper .total-block{ padding: 20px 10px 0; margin: 0; }
    .no-control.primary-grid.table thead th, .no-control.primary-grid.table tbody td{ padding: 14px 10px; }
    .no-control.primary-grid.table{ margin: 0; }

    .product-micro-detail .action-bar{ margin-bottom: 10px; }
    .product-micro-detail .action-bar .btn{ height: 40px; }
    .product-micro-detail .action-bar .form-group .form-control{ height: 40px; }
    .product-micro-detail .action-bar .form-group{ width: 80px; margin-bottom: 28px; }
    .number-input{ align-items: flex-start; }
    .number-input .action{ padding: 0; }
    .number-input .btn-icon{ width: 18px!important;height: 18px!important;min-width: 18px!important;min-height: 18px!important; margin-left: -24px!important; }
    .number-input .btn-icon> img{ width: 10px;height: 10px;}
    .number-input .form-group .form-control{ padding-right: 34px; }
    .number-input .form-group .form-control{ padding-right: 25px; }
}
@media (max-width:575px) {
    .form-outer{ padding: 0 20px; }
    .form-outer .form-block .form-body h2{ padding-bottom: 24px; }
    .form-outer .form-block .form-header{ margin: 30px 0; }
    .form-outer .form-block .form-footer { padding: 30px 0; }

    h2{ font-size: 26px; line-height: 32px; }
    .navbar{ padding: 15px; }
    .navbar .logo{ width: 80px; }

    .contact-block{ padding-top: 0; }
    .contact-details-block .content-heading{ font-size: 26px; line-height: 30px; padding-bottom: 25px; }
    .contact-listing li{ font-size: 14px; line-height: 18px; margin-bottom: 20px; }
    .contact-listing li img{ width: 20px; height: 20px; }

    .content-block{ padding: 30px 0; }

    .secondary-banner-block{ padding: 100px 15px; }
    .secondary-banner-block .description{ font-size: 22px; line-height: 30px; }

    .wrapper{ padding-bottom: 163px; }
    .footer-block{ padding: 15px 0; margin-top: -163px; }
    .footer-block .social-media-block > a{ padding: 6px; }
    .footer-block .social-media-block > a + a{ margin-left: 4px; }
    .footer-block .social-media-block{ margin-bottom: 6px; }
    .footer-block .social-media-block > a > img{ width: 26px; height: 26px; }
    .footer-block .linking-block .secondary-nav{ margin: 0 -10px 8px; }
    .footer-block .linking-block .secondary-nav li a{ font-size: 13px; line-height: 17px; }
    .footer-block .linking-block .secondary-nav li + li a{ margin-left: 14px; }
    .footer-block .linking-block .copyright-block span > a{ font-size: 13px; line-height: 17px; }
    .footer-block .linking-block .copyright-block span{ margin-bottom: 10px; }
    .footer-block .linking-block .copyright-block p{ font-size: 12px; line-height: 16px; }

    .product-navbar li > .product-nav-item { font-size: 14px; line-height: 18px; padding: 15px; }

    .product-micro-detail .que-modal-link{ margin-bottom: 16px; } 

    .sorting-block{ justify-content: flex-end;  }
    .sorting-block .form-group{ width: 100%; margin-right: 0; margin-bottom: 15px; }
    .sorting-block .sort-menu .btn-accent.dropdown-toggle{ margin-bottom: 15px; }

    .product-list-item .product-list-item-img, .product-list-item .product-list-item-img > img{ width: 60px; height: 60px; }
    .product-list-item .product-list-item-detail{ padding: 10px 30px 10px 20px; margin: 15px 0; width: calc(100% - 60px); }
    .product-list-item .product-list-item-detail::after{ bottom: -15px; }
    .product-list-item .product-list-item-detail span{ padding-bottom: 7px; }
    .product-list-item .product-list-item-detail::before{ right: 5px; background-size: 14px; }
    .product-list-item:hover .product-list-item-detail::before{ right: 5px; }
    .product-list-item .product-list-item-detail div.label-action > span{ line-height: 26px; font-size: 16px; }
    .product-list-item.simple-product .product-list-item-img{ margin-top: 30px; vertical-align: top; }
    .product-list-item .product-list-item-img{ margin-top: 30px; }
    .product-list-item{ align-items: flex-start; }
    .product-list-item .product-list-item-detail .form-group{ margin-top: 8px; }
    .product-list-item .product-list-item-detail span{ font-size: 16px; line-height: 20px; }
    .product-list-item .default-menu .dropdown-menu{ padding: 5px 0; margin-left: -10px!important; }

    .product-micro-detail .action-bar{ display: block; margin-bottom: 20px; }
    .product-micro-detail .action-bar .btn{ margin: 6px 6px 0 0; }

    .default-menu .dropdown-menu .dropdown-item{font-size: 14px; line-height: 18px; padding: 8px 10px; }

    .main-content{ padding: 25px 20px 15px; }

    .info-card{ height: calc(100% - 20px); margin-bottom: 20px; padding: 20px; }
    .info-card > img{ width: 90px; height: 90px; margin: 0 auto 10px; }
    .content-block.how-it-work .row{ margin-bottom: -20px; }

    .empty-cart-block .main-block h3{ font-size: 22px; line-height: 26px; }
    .empty-cart-block .main-block > img{ width: 130px;height: 110px;margin: 0 auto 20px;  }
    .empty-cart-block .main-block p{ font-size: 16px; line-height: 20px; padding-bottom: 15px; }

    .page-block{ margin: 20px 0 30px; }

    .alert-modal .event-img{ width: 60px; height: 60px; margin: 0 auto 18px; }
    .alert-modal .event-title{ font-size: 20px; line-height: 26px; padding-bottom: 12px; }
    .alert-modal .event-description{font-size: 14px; line-height: 22px; }

    .contact-card{ padding: 20px 80px 20px 25px; min-height: 115px; }
    .contact-card > img{ width: 90px;height: 75px; }

    .loader-outer{ margin-top: -30px; }
    .my-order-card > div .labeled-data p, .my-order-card > div .labeled-data span{ display: block; font-size: 14px; line-height: 20px; }
    .my-order-card .orderID{ font-size: 16px; line-height: 20px; }
    .my-order-card > div .labeled-data:last-of-type{ margin-bottom: 18px; }
 }

 .react-datepicker__day--weekend{
    color: #ccc;
    cursor: default;
    pointer-events: none;
 }